// Colors
$color-black: rgb(0, 0, 0);
$color-white: rgb(255, 255, 255);
$color-blue: rgb(58,107,255);
$color-lightblue: rgb(231,235,252);
$color-gray: rgb(86,90,104);
$color-lightgray: rgb(119,123,134);
$color-brown: rgb(138,99,93);

// Breakpoints
$xs: em(360);
$s: em(480);
$ms: em(720);
$m: em(960);
$ml: em(1200);
$l: em(1440);
$xl: em(1680);
$xxl: em(1920);

$max-xs: 'max-width: #{$xs - (1/16)}';
$min-xs: 'min-width: #{$xs}';
$max-s: 'max-width: #{$s - (1/16)}';
$min-s: 'min-width: #{$s}';
$max-ms: 'max-width: #{$ms - (1/16)}';
$min-ms: 'min-width: #{$ms}';
$max-m: 'max-width: #{$m - (1/16)}';
$min-m: 'min-width: #{$m}';
$max-ml: 'max-width: #{$ml - (1/16)}';
$min-ml: 'min-width: #{$ml}';
$max-l: 'max-width: #{$l - (1/16)}';
$min-l: 'min-width: #{$l}';
$max-xl: 'max-width: #{$xl - (1/16)}';
$min-xl: 'min-width: #{$xl}';
$max-xxl: 'max-width: #{$xxl - (1/16)}';
$min-xxl: 'min-width: #{$xxl}';

// Fonts
$font-family-default: 'Apercu Web', Arial, sans-serif;
$font-family-headline: 'Fortescue Web Display', Times, serif;
$font-family-headline-text: 'Fortescue Web Text', Times, serif;

// Font sizes
$font-size-default: rem(19);
$font-size-large: rem(25);
$font-size-small: rem(16);
$font-size-h1: rem(30);
$font-size-h2: rem(62);
$font-size-h3: rem(25);
$font-size-h4: rem(20);

// Font sizes MOBILE
$font-size-default-mobile: rem(16);
$font-size-large-mobile: rem(20);
$font-size-h2-mobile: rem(35);

// Line heights
$line-height-default: 1.6;

// Letter spacings
$letter-spacing-default: 0.05em;


// Easings
$ease-out: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in: cubic-bezier(0, 0, 0.2, 1);
$ease-in-out: cubic-bezier(0.55, 0, 0.1, 1);
$ease-fade: cubic-bezier(0, 0, 0.25, 1);

// Spacings
$space-xxl: 120px;
$space-xl: 80px;
$space-l: 64px;
$space-m: 40px;
$space-s: 20px;


