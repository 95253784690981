// HERO

.hero {
  position: relative;

  .herocontent {
    position: absolute;
    top:0;
    z-index: 1;
    padding-top: 12vw;
    padding-left: 5vw;

    h1 {
      font-size: 2.5vw;
      padding-bottom: 16px;
      white-space: nowrap;
    }

    p {
      width: 350px;
    }
  }

  @media ($max-l) {
    .herocontent {
      p {
        font-family: $font-family-default;
        font-size: $font-size-small;
        width: 280px;
      }
    }
  }

  @media ($max-m) {
    .herocontent {
      position: relative;
      padding: $space-m 5vw $space-xl 5vw;
      // background-color: $color-lightblue;

      h1 {
        font-size: $font-size-h1;
        padding-bottom: 16px;
        white-space: normal;
      }
    }
  }
}
