::selection {
  background-color: $color-black;
  color: $color-white;
}

html {
  background-color: $color-white;
  color: $color-black;
  font-family: $font-family-default;
  font-size: 1em;
  line-height: $line-height-default;
  letter-spacing: $letter-spacing-default;
  font-variant: no-common-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;

  // prevent long word wrap
  @media ($max-xs) {
    hyphens: auto;
  }
}

body {
  min-height: 100vh;
  overflow-y: scroll; // always show scrollbar
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: $font-size-default;
}

@media ($max-ms) {
  body {
    font-size: $font-size-default-mobile;
  }
}

main {
  flex-grow: 1;
}
