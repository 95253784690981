// FOOTER

footer {
  background-color: $color-black;
  color: $color-white;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: $space-s;

  padding: 90px 6vw;
  font-size: $font-size-small;

  > a {
    font-family: $font-family-headline-text;
    font-size: $font-size-h4;
    grid-column: 1 / 4;
  }

  a, ul {
    color: $color-white;
    margin-right: 20px;
    margin-bottom: 16px;
  }

  li {
    font-size: $font-size-small;
    display: inline-block;
  }

  a:hover {
    color: $color-blue;
  }

  > * > * {
    display: block;
  }
}

@media ($max-m) {
  footer {
    grid-template-columns: repeat(3, 1fr);

    > a {
      grid-column: 1 / 4;
    }

    > div {
      grid-row: 2;
    }
  }
}

@media ($max-ms) {
  footer {
    grid-template-columns: 1fr;
    > a {
      grid-column: 1;
    }

    > div() {
      grid-row: auto;
    }
  }
}
