html {
  -webkit-text-size-adjust: 100%; // iOS fix
  word-wrap: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

main {
  display: block; // IE fix
}

b,
strong {
  font-weight: bolder;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
  font-size: inherit;
}

ul,
ol,
p {
  margin: 0;
  padding: 0;
}

ul li {
  padding: 0;
  list-style-type: none;
}

blockquote,
figure {
  margin: 0;
}

figcaption {
  font-weight: inherit;
}

img,
video {
  width: 100%;
  height: auto;
  display: block;
  //vertical-align: top;
}

video {
  outline: 0;
}

iframe {
  border: none;
}

address {
  font-style: normal;
}

svg {
  display: block;
  //vertical-align: top;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: 0;
  cursor: pointer;
  color: inherit;
  background: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
}

// Hide outline without degrading a11y
a,
button {
  .js-focus-visible &:focus:not(.focus-visible) {
    outline: none;
  }
}

*,
*::before,
*::after {
  @media (prefers-reduced-motion: reduce) {
    animation-duration: 0.001s !important;
    transition-duration: 0.001s !important;
  }
}
