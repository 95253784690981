
// Rem + em
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function rem($values, $round: true) {
  $result: ();

  @each $value in $values {
    $val: strip-unit($value);
    $val: if($round, round($val), $val);
    $rem: if($val == 0, 0, $val / 16 * 1rem);
    $result: append($result, $rem, space);
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

@function em($values, $round: true) {
  $result: ();

  @each $value in $values {
    $val: strip-unit($value);
    $val: if($round, round($val), $val);
    $em: if($val == 0, 0, $val / 16 * 1em);
    $result: append($result, $em, space);
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}
