// TEAM & KONTAKT

.contentbox {
  width: 100%;
  padding: $space-xxl 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .textwrapper {
    width: 100%;
    max-width: 1200px;
  }

  h4 {
    font-size: $font-size-h3;
  }

  .contentwrapper {  // KONTAKT
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > * {
      margin-right: $space-m;
      margin-bottom: $space-m;
    }

    a {
      color: $color-white;
    }

    a:hover {
      color: $color-lightblue;
    }

    h3 {
      margin-bottom: 10px;
    }
  }
}

.teamgrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto;
  grid-row-gap: $space-xl;

  div:nth-child(1) { // Person links
    grid-area: 1 / 1 / 2 / 4;
    justify-self: center;
  }

  div:nth-child(4) { // Person rechts
    grid-area: 1 / 5 / 2 / 8;
    justify-self: center;
  }

  div:nth-child(2), div:nth-child(5) { // Sticky Name
    grid-area: 2 / 1 / 3 / 3;
  }

  div:nth-child(3), div:nth-child(6) { // Vita
    grid-area: 2 / 3 / 3 / 7;
    justify-self: end;
  }

  .stickyname {
    position: sticky;
    top: 140px;
  }
}

@media ($max-m) {
  .teamgrid {
    div:nth-child(2), div:nth-child(5) { // Sticky Name
      grid-area: 2 / 1 / 3 / 4;
    }

    div:nth-child(3), div:nth-child(6) { // Vita
      grid-area: 2 / 4 / 3 / 8;
    }
  }
}

@media ($max-ms) {
  .teamgrid {
    grid-row-gap: $space-m;

    div:nth-child(1) { // Person links
      grid-area: 1 / 1 / 2 / 8;
      justify-self: start;
    }

    div:nth-child(3) { // Vita links
      grid-area: 2 / 1 / 3 / 8;
      justify-self: start;
    }

    div:nth-child(4) { // Person rechts
      grid-area: 3 / 1 / 4 / 8;
      justify-self: start;
    }

    div:nth-child(6) { // Vita
      grid-area: 4 / 1 / 5 / 8;
      justify-self: start;
    }

    div:nth-child(2), div:nth-child(5) { // Name
      display: none;
    }
  }
}


.person {  // TEAM

  h3 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: $space-m;
  }

  .teamimg {
    background-color: $color-lightblue;
    margin-bottom: $space-m;
  }
}




.vita {
  padding-top: 5px;

  p {
    @extend .psmall;
    margin-bottom: 30px;
  }
  h4 {
    margin-top: 60px;
  }
  h4:first-child {
    margin-top: 0;
  }
}

.vita-hide {
  display: none;
  visibility: hidden;
}


////////////
// BUTTON //
////////////

.vitabutton {
  display: flex;
  align-items: baseline;
  color: $color-blue;

  svg {
    margin: 0 10px;
    transition: transform 200ms ease-in-out;
    transform: rotate(180deg);
    height: 13px;
    width: auto;

    line:nth-child(1) {
      fill: none;
      stroke: $color-blue;
      stroke-width: 3px;
    }

    line:nth-child(2) {
      fill: none;
      stroke: $color-black;
      stroke-width: 3px;
    }
  }
}

.vitabutton-toggle {
  svg {
    transform: rotate(0deg);
  }
}
