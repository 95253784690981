// DEFAULT

.default {
  h1 {
    font-size: $font-size-h2;

    margin-bottom: $space-m;
  }
  h2 {
    font-size: $font-size-h1;
    margin-top: $space-l;
  }
  h2:first-child {
    font-size: $font-size-h1;
    margin-top: 0;
  }
  h3 {
    font-size: $font-size-h3;
    margin-top: $space-m;
  }
  h4 {
    font-size: $font-size-h4;
    margin-top: $space-s;
  }
}

@media ($max-m) {
  .default {
    h1, h2, h3, h4, h5 {
      font-size: $font-family-headline-text;
    }

    h1 {
      font-size: $font-size-h2-mobile;
      margin-bottom: $space-m;
    }
    h2 {
      font-size: $font-size-h1;
      margin-top: $space-l;
    }
  }
}
