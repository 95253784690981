@font-face {
  font-family: 'Apercu Web';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fnt/apercu-medium-pro.woff2") format("woff2"), url("../fnt/apercu-medium-pro.woff") format("woff");
}

@font-face {
  font-family: 'Fortescue Web Display';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fnt/fortescue-display-regular-pro.woff2") format("woff2"), url("../fnt/fortescue-display-regular-pro.woff") format("woff");
}

@font-face {
  font-family: 'Fortescue Web Text';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fnt/fortescue-text-regular-pro.woff2") format("woff2"), url("../fnt/fortescue-text-regular-pro.woff") format("woff");
}

html {
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

main {
  display: block;
}

b,
strong {
  font-weight: bolder;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
  font-size: inherit;
}

ul,
ol,
p {
  margin: 0;
  padding: 0;
}

ul li {
  padding: 0;
  list-style-type: none;
}

blockquote,
figure {
  margin: 0;
}

figcaption {
  font-weight: inherit;
}

img,
video {
  width: 100%;
  height: auto;
  display: block;
}

video {
  outline: 0;
}

iframe {
  border: none;
}

address {
  font-style: normal;
}

svg {
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: 0;
  cursor: pointer;
  color: inherit;
  background: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
}

.js-focus-visible a:focus:not(.focus-visible), .js-focus-visible
button:focus:not(.focus-visible) {
  outline: none;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.001s !important;
    transition-duration: 0.001s !important;
  }
}

::selection {
  background-color: black;
  color: white;
}

html {
  background-color: white;
  color: black;
  font-family: "Apercu Web", Arial, sans-serif;
  font-size: 1em;
  line-height: 1.6;
  letter-spacing: 0.05em;
  font-variant: no-common-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

@media (max-width: 22.4375em) {
  html {
    hyphens: auto;
  }
}

body {
  min-height: 100vh;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 1.1875rem;
}

@media (max-width: 44.9375em) {
  body {
    font-size: 1rem;
  }
}

main {
  flex-grow: 1;
}

.o-nav-icon {
  width: 100%;
  height: auto;
  display: block;
  fill: none;
  pointer-events: none;
  stroke-width: 2.5;
  stroke: currentColor;
}

.o-nav-icon__line {
  stroke-dasharray: 24;
  stroke-dashoffset: 0;
  opacity: 1;
  transition: stroke-dashoffset 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 100ms cubic-bezier(0, 0, 0.25, 1);
  transition-delay: 200ms, 200ms;
}

.o-nav-icon__line:nth-child(2) {
  transition-delay: 300ms, 300ms;
}

.o-nav-icon__line:nth-child(3) {
  transition-delay: 400ms, 400ms;
}

.o-nav-icon--active .o-nav-icon__line {
  stroke-dashoffset: 24;
  opacity: 0;
  transition-delay: 0ms, 200ms;
}

.o-nav-icon--active .o-nav-icon__line:nth-child(1) {
  transition-delay: 200ms, 400ms;
}

.o-nav-icon--active .o-nav-icon__line:nth-child(2) {
  transition-delay: 100ms, 300ms;
}

.o-nav-icon__cross {
  stroke-dasharray: 33.941123962402344;
  stroke-dashoffset: 33.941123962402344;
  opacity: 0;
  transition: stroke-dashoffset 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 100ms cubic-bezier(0, 0, 0.25, 1);
  transition-delay: 0ms, 200ms;
}

.o-nav-icon__cross:nth-child(5) {
  transition-delay: 100ms, 300ms;
}

.o-nav-icon--active .o-nav-icon__cross {
  stroke-dashoffset: 0;
  opacity: 1;
  transition-delay: 300ms, 300ms;
}

.o-nav-icon--active .o-nav-icon__cross:nth-child(5) {
  transition-delay: 400ms, 400ms;
}

.o-figure {
  width: 100%;
  outline: 1px solid transparent;
}

.o-figure__wrapper {
  position: relative;
}

@media (min-width: 45em) {
  .o-figure__wrapper--small {
    display: none;
  }
}

@media (max-width: 44.9375em) {
  .o-figure__wrapper--large {
    display: none;
  }
}

.o-figure__wrapper > * {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.o-figure__wrapper > img {
  object-fit: cover;
}

.o-figure__wrapper::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(119, 123, 134, 0.1);
  transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1);
}

.o-figure__wrapper--transparent::after {
  opacity: 0;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  transition: opacity 700ms cubic-bezier(0, 0, 0.25, 1);
  opacity: 1;
  backface-visibility: hidden;
}

.no-js .lazyload {
  display: none;
}

h1, .h1 {
  font-family: "Fortescue Web Display", Times, serif;
  font-size: 1.875rem;
}

h2, .h2 {
  font-family: "Fortescue Web Display", Times, serif;
  font-size: 3.875rem;
  margin-bottom: 64px;
}

h3, .h3 {
  font-family: "Fortescue Web Text", Times, serif;
  font-size: 1.5625rem;
}

h4, .h4 {
  font-family: "Fortescue Web Text", Times, serif;
  font-size: 1.25rem;
}

h5, .h5 {
  font-family: "Fortescue Web Text", Times, serif;
  font-size: 1.25rem;
}

p, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  max-width: 600px;
}

.pdefault {
  font-size: 1.1875rem;
}

.plarge {
  font-family: "Apercu Web", Arial, sans-serif;
  font-size: 1.5625rem;
}

.psmall, .vita p {
  font-family: "Apercu Web", Arial, sans-serif;
  font-size: 1rem;
}

@media (max-width: 44.9375em) {
  h2, .h2 {
    font-family: "Fortescue Web Text", Times, serif;
    font-size: 2.1875rem;
    margin-bottom: 40px;
  }
  .plarge {
    font-size: 1.25rem;
  }
}

a {
  color: #8a635d;
  transition: color 0.1s ease-in-out;
}

a:hover {
  color: #3a6bff;
}

.lightgray {
  background-color: #777b86;
  color: white;
}

.lightblue {
  background-color: #e7ebfc;
}

.brown {
  background-color: #8a635d;
  color: white;
}

.logo {
  z-index: 2;
}

.logo svg {
  height: 40px;
  width: auto;
}

.logo svg polygon {
  fill: black;
}

.logo svg path {
  fill: #3a6bff;
}

@media (max-width: 59.9375em) {
  .logo svg {
    height: 26px;
  }
}

header {
  background-color: white;
  z-index: 100;
  height: 98px;
  position: sticky;
  top: 0;
  padding: 0 5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header a:nth-child(1) {
  margin-left: 0;
}

header a, header ul {
  color: black;
  margin-left: 24px;
  display: inline-block;
}

header li {
  display: inline-block;
}

header li a {
  color: #777b86;
}

nav {
  display: flex;
  align-items: center;
}

nav button {
  width: 32px;
  height: auto;
  visibility: hidden;
  pointer-events: none;
}

@media (max-width: 59.9375em) {
  header {
    height: 50px;
  }
  .nav {
    position: absolute;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 100%;
    top: 0;
    left: 0;
    padding: 90px 5vw 40px;
    background-color: white;
    visibility: hidden;
    pointer-events: none;
    transition: transform 250ms ease-out, visibility 0ms linear 250ms;
    transform: translateY(-100%);
  }
  .nav a {
    margin: 0;
    margin-bottom: 20px;
  }
  .pseudo {
    left: 0;
    position: absolute;
    background-color: white;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  nav button {
    visibility: visible;
    pointer-events: all;
    z-index: 2;
  }
}

.menu-visible {
  visibility: visible;
  pointer-events: all;
  transition-delay: 0ms, 0ms;
  transform: translateY(0);
}

footer {
  background-color: black;
  color: white;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 20px;
  padding: 90px 6vw;
  font-size: 1rem;
}

footer > a {
  font-family: "Fortescue Web Text", Times, serif;
  font-size: 1.25rem;
  grid-column: 1 / 4;
}

footer a, footer ul {
  color: white;
  margin-right: 20px;
  margin-bottom: 16px;
}

footer li {
  font-size: 1rem;
  display: inline-block;
}

footer a:hover {
  color: #3a6bff;
}

footer > * > * {
  display: block;
}

@media (max-width: 59.9375em) {
  footer {
    grid-template-columns: repeat(3, 1fr);
  }
  footer > a {
    grid-column: 1 / 4;
  }
  footer > div {
    grid-row: 2;
  }
}

@media (max-width: 44.9375em) {
  footer {
    grid-template-columns: 1fr;
  }
  footer > a {
    grid-column: 1;
  }
  footer > div {
    grid-row: auto;
  }
}

.hero {
  position: relative;
}

.hero .herocontent {
  position: absolute;
  top: 0;
  z-index: 1;
  padding-top: 12vw;
  padding-left: 5vw;
}

.hero .herocontent h1 {
  font-size: 2.5vw;
  padding-bottom: 16px;
  white-space: nowrap;
}

.hero .herocontent p {
  width: 350px;
}

@media (max-width: 89.9375em) {
  .hero .herocontent p {
    font-family: "Apercu Web", Arial, sans-serif;
    font-size: 1rem;
    width: 280px;
  }
}

@media (max-width: 59.9375em) {
  .hero .herocontent {
    position: relative;
    padding: 40px 5vw 80px 5vw;
  }
  .hero .herocontent h1 {
    font-size: 1.875rem;
    padding-bottom: 16px;
    white-space: normal;
  }
}

.textbox {
  width: 100%;
  padding: 120px 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textbox .textwrapper {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
}

.textbox .text {
  align-self: flex-end;
}

.textbox .text * {
  margin-bottom: 20px;
}

.textbox .text ul li:before {
  content: '\2014';
  padding-right: 10px;
}

.textbox .text ol {
  list-style-position: inside;
}

.textbox .text ol li {
  padding-left: 6px;
}

.textbox .plarge {
  margin-bottom: 80px;
}

.textbox h3, .textbox .h3 {
  margin-bottom: 40px;
}

.textbox .boxes {
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
}

.textbox .boxes > div {
  margin-top: 40px;
  text-align: center;
  background-color: white;
  color: black;
  min-width: 440px;
  width: 33vw;
  max-width: 700px;
  padding: 64px 40px;
}

.textbox .arrowlink {
  display: flex;
  align-items: baseline;
}

.textbox .arrowlink svg {
  height: 18px;
  width: auto;
  margin-right: 10px;
}

.textbox .arrowlink svg path:nth-child(1) {
  fill: none;
  stroke: black;
  stroke-width: 2.1px;
}

.textbox .arrowlink svg path:nth-child(2) {
  fill: none;
  stroke: #3a6bff;
  stroke-width: 2.1px;
}

@media (max-width: 59.9375em) {
  .textbox {
    padding: 80px 5vw;
  }
  .textbox .boxes > div {
    min-width: auto;
    width: 100%;
  }
}

@media (max-width: 29.9375em) {
  .textbox {
    padding: 80px 2.5vw;
  }
  .textbox .boxes > div {
    min-width: auto;
    width: 100%;
    padding: 64px 2.5vw;
  }
}

.contentbox {
  width: 100%;
  padding: 120px 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentbox .textwrapper {
  width: 100%;
  max-width: 1200px;
}

.contentbox h4 {
  font-size: 1.5625rem;
}

.contentbox .contentwrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contentbox .contentwrapper > * {
  margin-right: 40px;
  margin-bottom: 40px;
}

.contentbox .contentwrapper a {
  color: white;
}

.contentbox .contentwrapper a:hover {
  color: #e7ebfc;
}

.contentbox .contentwrapper h3 {
  margin-bottom: 10px;
}

.teamgrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto;
  grid-row-gap: 80px;
}

.teamgrid div:nth-child(1) {
  grid-area: 1 / 1 / 2 / 4;
  justify-self: center;
}

.teamgrid div:nth-child(4) {
  grid-area: 1 / 5 / 2 / 8;
  justify-self: center;
}

.teamgrid div:nth-child(2), .teamgrid div:nth-child(5) {
  grid-area: 2 / 1 / 3 / 3;
}

.teamgrid div:nth-child(3), .teamgrid div:nth-child(6) {
  grid-area: 2 / 3 / 3 / 7;
  justify-self: end;
}

.teamgrid .stickyname {
  position: sticky;
  top: 140px;
}

@media (max-width: 59.9375em) {
  .teamgrid div:nth-child(2), .teamgrid div:nth-child(5) {
    grid-area: 2 / 1 / 3 / 4;
  }
  .teamgrid div:nth-child(3), .teamgrid div:nth-child(6) {
    grid-area: 2 / 4 / 3 / 8;
  }
}

@media (max-width: 44.9375em) {
  .teamgrid {
    grid-row-gap: 40px;
  }
  .teamgrid div:nth-child(1) {
    grid-area: 1 / 1 / 2 / 8;
    justify-self: start;
  }
  .teamgrid div:nth-child(3) {
    grid-area: 2 / 1 / 3 / 8;
    justify-self: start;
  }
  .teamgrid div:nth-child(4) {
    grid-area: 3 / 1 / 4 / 8;
    justify-self: start;
  }
  .teamgrid div:nth-child(6) {
    grid-area: 4 / 1 / 5 / 8;
    justify-self: start;
  }
  .teamgrid div:nth-child(2), .teamgrid div:nth-child(5) {
    display: none;
  }
}

.person h3 {
  margin-bottom: 10px;
}

.person p {
  margin-bottom: 40px;
}

.person .teamimg {
  background-color: #e7ebfc;
  margin-bottom: 40px;
}

.vita {
  padding-top: 5px;
}

.vita p {
  margin-bottom: 30px;
}

.vita h4 {
  margin-top: 60px;
}

.vita h4:first-child {
  margin-top: 0;
}

.vita-hide {
  display: none;
  visibility: hidden;
}

.vitabutton {
  display: flex;
  align-items: baseline;
  color: #3a6bff;
}

.vitabutton svg {
  margin: 0 10px;
  transition: transform 200ms ease-in-out;
  transform: rotate(180deg);
  height: 13px;
  width: auto;
}

.vitabutton svg line:nth-child(1) {
  fill: none;
  stroke: #3a6bff;
  stroke-width: 3px;
}

.vitabutton svg line:nth-child(2) {
  fill: none;
  stroke: black;
  stroke-width: 3px;
}

.vitabutton-toggle svg {
  transform: rotate(0deg);
}

.default h1 {
  font-size: 3.875rem;
  margin-bottom: 40px;
}

.default h2 {
  font-size: 1.875rem;
  margin-top: 64px;
}

.default h2:first-child {
  font-size: 1.875rem;
  margin-top: 0;
}

.default h3 {
  font-size: 1.5625rem;
  margin-top: 40px;
}

.default h4 {
  font-size: 1.25rem;
  margin-top: 20px;
}

@media (max-width: 59.9375em) {
  .default h1, .default h2, .default h3, .default h4, .default h5 {
    font-size: "Fortescue Web Text", Times, serif;
  }
  .default h1 {
    font-size: 2.1875rem;
    margin-bottom: 40px;
  }
  .default h2 {
    font-size: 1.875rem;
    margin-top: 64px;
  }
}
