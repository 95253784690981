// SCHRIFT

h1, .h1 {
  font-family: $font-family-headline;
  font-size: $font-size-h1;
}

h2, .h2 {
  font-family: $font-family-headline;
  font-size: $font-size-h2;
  margin-bottom: $space-l;
}

h3, .h3 {
  font-family: $font-family-headline-text;
  font-size: $font-size-h3;
}

h4, .h4 {
  font-family: $font-family-headline-text;
  font-size: $font-size-h4;
}

h5, .h5 {
  font-family: $font-family-headline-text;
  font-size: $font-size-h4;
}

p, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  max-width: 600px;
}

.pdefault {
  font-size: $font-size-default;
}

.plarge {
  font-family: $font-family-default;
  font-size: $font-size-large;
}

.psmall {
  font-family: $font-family-default;
  font-size: $font-size-small;
}

@media ($max-ms) {
  h2, .h2 {
    font-family: $font-family-headline-text;
    font-size: $font-size-h2-mobile;
    margin-bottom: $space-m;
  }

  .plarge {
    font-size: $font-size-large-mobile;
  }
}

// LINKS

a {
  color: $color-brown;
  transition: color 0.1s ease-in-out;
}

a:hover {
  color: $color-blue;
}


// FARBIGE KÄSTEN

.lightgray {
  background-color: $color-lightgray;
  color: $color-white;
}

.lightblue {
  background-color: $color-lightblue;
}

.brown {
  background-color: $color-brown;
  color: $color-white;
}
