@font-face {
  font-family: 'Apercu Web';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fnt/apercu-medium-pro.woff2') format('woff2'),
    url('../fnt/apercu-medium-pro.woff') format('woff');
}

@font-face {
  font-family: 'Fortescue Web Display';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fnt/fortescue-display-regular-pro.woff2') format('woff2'),
    url('../fnt/fortescue-display-regular-pro.woff') format('woff');
}

@font-face {
  font-family: 'Fortescue Web Text';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fnt/fortescue-text-regular-pro.woff2') format('woff2'),
    url('../fnt/fortescue-text-regular-pro.woff') format('woff');
}
