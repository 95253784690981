// TEXT

.textbox {
  width: 100%;
  padding: 120px 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .textwrapper {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
  }

  .text {
    align-self: flex-end;

    * {
      margin-bottom: $space-s;
    }

    ul li:before {
      content: '\2014';
      padding-right: 10px;
    }
    ol {
      list-style-position: inside;
    }
    ol li {
      padding-left: 6px;
    }
  }

  .plarge {
    margin-bottom: $space-xl;
  }
  h3, .h3 {
    margin-bottom: $space-m;
  }

  // Beteiligungsfokus
  .boxes {
    margin-top: $space-m;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;

    > div {
      margin-top: $space-m;
      text-align: center;
      background-color: $color-white;
      color: $color-black;
      min-width: 440px;
      width: 33vw;
      max-width: 700px;
      padding: 64px 40px;
    }
  }

  //Beteiligungen
  .arrowlink {
    display: flex;
    align-items: baseline;

    svg {
      height: 18px;
      width: auto;
      margin-right: 10px;

      path:nth-child(1) {
        fill: none;
        stroke: $color-black;
        stroke-width: 2.1px;
      }

      path:nth-child(2) {
        fill: none;
        stroke: $color-blue;
        stroke-width: 2.1px;
      }
    }
  }
}

@media ($max-m) {
  .textbox {
    padding: 80px 5vw;

    .boxes {
      > div {
        min-width: auto;
        width: 100%
      }
    }
  }
}

@media ($max-s) {
  .textbox {
    padding: 80px 2.5vw;

    .boxes {
      > div {
        min-width: auto;
        width: 100%;
        padding: 64px 2.5vw;
      }
    }
  }
}
