$header-height-big: 98px;
$header-height-small: 50px;
$logo-small: 26px;
$logo-big: 40px;

// LOGO
.logo {
  z-index: 2;

  svg {
    height: $logo-big;
    width: auto;

    polygon {
      fill: $color-black;
    }

    path {
      fill: $color-blue;
    }
  }

  @media ($max-m) {
    svg {
      height: $logo-small;
    }
  }
}

header {
  background-color: $color-white;
  z-index: 100;
  height: $header-height-big;
  position: sticky;
  top: 0;
  padding: 0 5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a:nth-child(1) {
    margin-left: 0;
  }

  a, ul {
    color: $color-black;
    margin-left: 24px;
    display: inline-block;
  }

  li {
    display: inline-block;
  }
  li a {
    color: $color-lightgray;
  }
}

nav {
  display: flex;
  align-items: center;

  button {
    width: 32px;
    height: auto;
    visibility: hidden;
    pointer-events: none;
  }
}


// MOBILE

@media ($max-m) {
  header {
    height: $header-height-small;
  }

  .nav {
    position: absolute;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 100%;
    top: 0;
    left: 0;
    padding: $space-m * 2.25 5vw $space-m;
    background-color: $color-white;

    visibility: hidden;
    pointer-events: none;
    transition: transform 250ms ease-out, visibility 0ms linear 250ms;
    transform: translateY(-100%);

    a {
      margin: 0;
      margin-bottom: $space-s;
    }
  }

  .pseudo {
    left: 0;
    position: absolute;
    background-color: $color-white;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  nav {
    button {
      visibility: visible;
      pointer-events: all;
      z-index: 2;
    }
  }
}

.menu-visible {
  visibility: visible;
  pointer-events: all;
  transition-delay: 0ms, 0ms;
  transform: translateY(0);
}

