.o-figure {
  width: 100%; // Necessary for flexbox layouts, otherwise height == 0
  outline: 1px solid transparent; // Fix artefacts in Safari
}

.o-figure__wrapper {
  position: relative;

  // Art directed images:
  // <picture> with inline --ratio vars + mq for intrinsic size could be better, but no IE support.
  &--small {
    @media ($min-ms) {
      display: none;
    }
  }

  &--large {
    @media ($max-ms) {
      display: none;
    }
  }

  // Image, video, iframe
  > * {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1; // Make image draggable
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  > img {
    object-fit: cover;
  }

  // Background: animate opacity instead of bg-color for better perf
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color-lightgray, 0.1); // Works on most backgrounds
    transition: opacity 500ms $ease-fade;
  }

  &--transparent::after {
    opacity: 0;
  }
}


// lazyload
.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  transition: opacity 700ms $ease-fade;
  opacity: 1;
  backface-visibility: hidden; // Fixes position shift after transition
}

.no-js .lazyload {
  display: none;
}
